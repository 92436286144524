<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="section_1 flex-row">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNGfb106f120b2264356d53971859400930.png"
        />
        <span class="text_1">TARGET 4 U LIMITED </span>
        <div class="box_2 flex-col justify-between">
          <span class="text_2" @click="goPage('home')">Home</span>
        
        </div>
        <span class="text_3" @click="goPage('product')">Product</span>
        <span class="text_4" @click="goPage('services')">Services</span>
        <span class="text_5" @click="goPage('contact')">Contact</span>
      </div>
      <div class="section_3 flex-col">
        <div class="text-wrapper_1 flex-col">
          <span class="text_6">Building Your Vision</span>
        </div>
        <span class="text_7">
          At TARGET 4 U LIMITED, we turn your construction dreams into reality with precision, quality, and reliability. Partner with us for unparalleled construction services in the residential, commercial, and industrial sectors.
        </span>
      </div>
      <div class="text-wrapper_2 flex-row justify-between">
        <span class="text_8">Reputation Guarantee</span>
        <span class="text_9">
          TARGET 4 U LIMITED has won a good reputation in the industry with its high-quality construction and professional customer service, and has become a trusted construction partner for customers.

        </span>
      </div>
      <div class="section_4 flex-row">
        <div class="block_1 flex-col justify-between">
          <div class="group_1 flex-row">
            <div class="text-group_1 flex-col justify-between">
              <span class="text_10">100%</span>
              <span class="text_11">Professional Team</span>
            </div>
          </div>
          <div class="group_2 flex-row">
            <div class="text-group_2 flex-col justify-between">
              <span class="text_12">15+</span>
              <span class="text_13">Suppliers</span>
            </div>
          </div>
        </div>
        <div class="block_2 flex-col justify-between">
          <div class="block_3 flex-row">
            <div class="text-group_3 flex-col justify-between">
              <span class="text_14">1000+</span>
              <span class="text_15">Team Number</span>
            </div>
          </div>
          <div class="block_4 flex-row">
            <div class="text-group_4 flex-col justify-between">
              <span class="text_16">300+</span>
              <span class="text_17">Customer Support</span>
            </div>
          </div>
        </div>
        <div class="block_5 flex-row justify-between">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/FigmaDDSSlicePNG14f3d8bc465fa86fdb9b5d2274cd30c5.png"
          />
          <div class="text-wrapper_3 flex-col">
            <span class="text_18">High-quality Construction</span>
            <span class="text_19">
              TARGET 4 U LIMITED has won high praise from customers with its high-quality construction standards and excellent craftsmanship, ensuring that each project meets or exceeds industry standards.
            </span>
            <span class="text_20">Professional Customer Service</span>
            <span class="text_21">
              The company is well-known for its professional customer service team, which can quickly respond to customer needs and provide personalized solutions, and customer satisfaction is very high.
            </span>
          </div>
        </div>
      </div>
      <span class="text_22">Company Advantages</span>
      <div class="section_5 flex-row justify-between">
        <div class="text-group_5 flex-col">
          <span class="text_23">01</span>
          <span class="text_24">Exquisite Craftsmanship</span>
          <span class="text_25">
            TARGET 4 U LIMITED has won the trust of customers with its exquisite craftsmanship and strict quality control, ensuring that each project meets the highest standards.
          </span>
        </div>
        <div class="text-group_6 flex-col">
          <span class="text_26">02</span>
          <span class="text_27">Efficient Management</span>
          <span class="text_28">
            The company has an experienced management team that can efficiently coordinate various project stages to ensure that the project is delivered on time and well under budget control.
          </span>
        </div>
        <div class="text-group_7 flex-col">
          <span class="text_29">03</span>
          <span class="text_30">Customer Satisfaction</span>
          <span class="text_31">
            Through high-quality services and personalized solutions, TARGET 4 U LIMITED continues to maintain high customer satisfaction and has won long-term cooperation from many customers.
          </span>
        </div>
      </div>
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/FigmaDDSSlicePNG979924d34d4b843642b91df5c8248bbf.png"
      />
      <div class="text-wrapper_4 flex-row justify-between">
        <span class="text_32">Corporate Vision</span>
        <span class="text_33">
          Create outstanding buildings and a better future.
        </span>
      </div>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/FigmaDDSSlicePNGe0d7169141a318252fd7298c78191698.png"
      />
      <div class="section_6 flex-row">
        <span class="text_34" @click="goPage('home')">Home</span>
        <span class="text_35" @click="goPage('product')">Product</span>
        <div class="image-text_1 flex-row justify-between">
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="./assets/img/FigmaDDSSlicePNG567cb441c93f0eedfc1069802473b1a9.png"
          />
          <span class="text-group_8">TARGET 4 U LIMITED </span>
        </div>
        <span class="text_36" @click="goPage('services')">Services</span>
        <span class="text_37" @click="goPage('contact')">Contacty</span>
      </div>
      <span class="text_38">Contacts&nbsp;us</span>
    </div>
    <div class="box_3 flex-col">
      <div class="box_4 flex-col"></div>
      <span class="text_39">©2024 All  rights TARGET 4 U LIMITED </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    goPage(path){
      this.$router.push({name:path})
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />