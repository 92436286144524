import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_guanwang2 from '../views/lanhu_guanwang2/index.vue'
import lanhu_guanwang1 from '../views/lanhu_guanwang1/index.vue'
import lanhu_guanwang3 from '../views/lanhu_guanwang3/index.vue'
import lanhu_guanwang4 from '../views/lanhu_guanwang4/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/product',
    name: 'product',
    component: lanhu_guanwang2
  },
  {
    path: '/home',
    name: 'home',
    component: lanhu_guanwang1
  },
  {
    path: '/services',
    name: 'services',
    component: lanhu_guanwang3
  },
  {
    path: '/contact',
    name: 'contact',
    component: lanhu_guanwang4
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
