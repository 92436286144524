<template>
  <div class="page flex-col">
    <div class="group_1 flex-row">
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="./assets/img/FigmaDDSSlicePNGfb106f120b2264356d53971859400930.png"
      />
      <span class="text_1">TARGET 4 U LIMITED </span>
      <div class="group_2 flex-col justify-between">
        <span class="text_2" @click="goPage('home')">Home</span>
      </div>
      <span class="text_3" @click="goPage('product')">Product</span>
      <span class="text_4" @click="goPage('services')">Services</span>
      <span class="text_5" @click="goPage('contact')">Contact</span>
    </div>
    <div class="group_3 flex-row justify-between">
      <div class="group_4 flex-col">
        <div class="text-wrapper_1 flex-col"><span class="text_6">Contact us for business </span></div>
        <span class="text_7">
          Cooperation and project support, please feel free to contact us
        </span>
        <div class="text-group_1 flex-col justify-between">
          <span class="text_8">Email</span>
          <span class="text_9">knoflasolesbeece19846@gmail.com </span>
        </div>
        <div class="text-group_2 flex-col justify-between">
          <span class="text_10">Call&nbsp;us</span>
          <span class="text_11">+447442237532</span>
        </div>
        <div class="text-group_2 flex-col justify-between">
          <span class="text_10">Address</span>
          <span class="text_11">Manhattan House CROWTHORNE, RG45 7AY United Kingdom</span>
        </div>
      </div>
      <div class="group_5 flex-col">
        <div class="text-wrapper_2 flex-col"><input v-model="formName" class="text_12" placeholder="Your Name"/></div>
        <div class="text-wrapper_3 flex-col"><input v-model="formEmail" class="text_13" placeholder="Email Address"/></div>
        <div class="text-wrapper_4 flex-col"><textarea v-model="formMessage" rows="5" class="text_14"  placeholder="Write Message"> </textarea></div>
        <div class="text-wrapper_5 flex-col" @click="entersubmit()"><span class="text_15" >{{issub?'laoding...':'Enter'}}</span></div>
      </div>
    </div>
    <div class="text-group_3 flex-col justify-between">
      <span class="text_16">Design Inspiration</span>
      <span class="text_17">
        TARGET 4 U LIMITED's design inspiration comes from a deep understanding and respect for the natural and human environment. We focus on combining modern architectural technology with traditional aesthetics, and strive to make each project not only practical, but also integrated into the local culture and landscape. Through in-depth communication with customers, we incorporate the personalized needs of customers into the design to create unique and functional architectural works. Our team constantly draws inspiration from leading global architectural cases, while also focusing on innovation to ensure that each building is a perfect combination of art and function, providing users with a beautiful, comfortable and environmentally friendly living and working environment.
      </span>
    
    </div>
    <img
      class="image_2"
      referrerpolicy="no-referrer"
      src="./assets/img/FigmaDDSSlicePNGe6d9ae8504766cc0dc3d69d2e18b09a8.png"
    />
    <span class="text_19">Gallery</span>
    <div class="group_6 flex-col">
      <div class="image-wrapper_1 flex-row">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          :src="item.lanhuimage0"
          v-for="(item, index) in loopData0"
          :key="index"
        />
      </div>
    </div>
    <div class="group_7 flex-row">
      <span class="text_20" @click="goPage('home')">Home</span>
      <span class="text_21" @click="goPage('product')">Product</span>
      <div class="image-text_1 flex-row justify-between">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNG567cb441c93f0eedfc1069802473b1a9.png"
        />
        <span class="text-group_4">TARGET 4 U LIMITED </span>
      </div>
      <span class="text_22" @click="goPage('services')">Services</span>
      <span class="text_23"  @click="goPage('contact')">Contact</span>
    </div>
    <div class="group_8 flex-col">
      <div class="group_9 flex-col"></div>
      <span class="text_24">©2024 All  rights TARGET 4 U LIMITED </span>
    </div>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuimage0: 'https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNG585631c4fff014494c5482e53c3d664e.png'
        },
        {
          lanhuimage0: 'https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNGf4c43cb6f39c148d4a44ec4253909b38.png'
        },
        {
          lanhuimage0: 'https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNG9e3fa5655f70a4a06300412ab52d84a1.png'
        }
      ],
      constants: {},
      issub:false,
      formMessage:'',
      formEmail:'',
      formName:'',
    };
  },
  methods: {
    goPage(path){
      this.$router.push({name:path})
    },
    entersubmit(){
      if(!this.formName){
        alert('Please enter your name')
        return
      }
      if(!this.formEmail){
        alert('Please enter Email Address')
        return
      }
      if(!this.formMessage){
        alert('Please  Write Message')
        return
      }
      this.issub = true
      setTimeout(() => {
        this.issub = false
        this.formName = ''
        this.formEmail = ''
        this.formMessage = ''
        alert('Thanks for your feedback')
      }, 1500);
    },
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />