<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="section_2 flex-row">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNGfb106f120b2264356d53971859400930.png"
        />
        <span class="text_1">TARGET 4 U LIMITED </span>
        <div class="box_1 flex-col justify-between">
          <span class="text_2" @click="goPage('home')">Home</span>
          
        </div>
        <span class="text_3" @click="goPage('product')">Product</span>
     
        <span class="text_4" @click="goPage('services')">Services</span>
        <span class="text_5" @click="goPage('contact')">Contact</span>
      </div>
      <div class="section_3 flex-row justify-between">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNGabc5bee0c9a2b880e6af0431bedbd656.png"
        />
        <div class="group_1 flex-col justify-between">
          <div class="text-wrapper_1 flex-col"><span class="text_6">About Architecture</span></div>
          <span class="text_7">
            Architecture is not only a combination of structure and design, but also a perfect fusion of functionality and aesthetics to create practical and beautiful spaces.
          </span>
        </div>
      </div>
      <div class="section_4 flex-row justify-between">
        <div class="box_3 flex-col justify-between">
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/img/FigmaDDSSlicePNG8adc061f651d33417f297c2e608c3f2d.png"
          />
          <div class="text-group_1 flex-col justify-between">
            <span class="text_8">More than 25 years of industry experience</span>
            <span class="text_9">
              We have successfully served 3000+ customers
            </span>
          </div>
        </div>
        <div class="list_1 flex-row">
          <div class="image-text_1 flex-col justify-between" v-for="(item, index) in loopData0" :key="index">
            <div class="text-wrapper_2 flex-col" :style="{ background: item.lanhuBg1,backgroundSize:'100% 100%' }">
              <span class="text_10" v-html="item.lanhutext0"></span>
            </div>
            <span class="text-group_2" v-html="item.lanhutext1"></span>
          </div>
        </div>
      </div>
      <div class="section_5 flex-row justify-between">
        <div class="text-wrapper_3 flex-col">
          <span class="text_11">The beauty of architecture</span>
          <span class="text_12">
            The beauty of architecture lies not only in the elegance and grandeur of its appearance, but also in the perfect combination of design and function. Through clever design and the use of materials, architecture can evoke people's emotional resonance and create a harmonious and pleasant spatial experience. Architecture is not only a place to live and work, but also a crystallization of art and technology, reflecting the creativity and wisdom of designers. Each building tells a unique story, reflecting the characteristics of culture, history and environment, thus giving people a deep impression and enjoyment visually and psychologically.
          </span>
        
        </div>
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNGd598705849d68274371fae33cfabc0b4.png"
        />
      </div>
      <div class="text-wrapper_4 flex-row justify-between">
        <span class="text_16">Industry <br> Goal</span>
        <span class="text_17">
          TARGET 4 U LIMITED is committed to becoming a leading enterprise in the construction industry, setting an industry benchmark by providing high-quality construction services and excellent customer experience.
        </span>
      </div>
      <div class="image_5 lex-row justify-between">

        <div class="section_7 flex-col">
        <div class="text-wrapper_5 flex-col">
          <span class="paragraph_1">
            Streamlined design
          </span>
        </div>
      </div>
      <div class="section_8 flex-col">
        <div class="text-wrapper_6 flex-col">
          <span class="paragraph_2">
            Artistic atmosphere
          </span>
        </div>
      </div>
  
      <div class="section_9 flex-col">
        <div class="text-wrapper_7 flex-col">
          <span class="paragraph_3">
            Retro charm
          </span>
        </div>
      </div>
           
      <div class="section_10 flex-col">
        <div class="text-wrapper_8 flex-col">
          <span class="paragraph_4">
            Green and safe
          </span>
        </div>
      </div>
      
      <div class="section_11 flex-col">
        <div class="text-wrapper_9 flex-col">
          <span class="paragraph_5">
            Green and safe
          </span>
        </div> 
      </div>
      </div>
      <!-- <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/FigmaDDSSlicePNG4111d0ac169f975fbc014eb0bf9b7dc1.png"
      /> -->
      <div class="section_6 flex-row">
        <span class="text_18" @click="goPage('home')">Home</span>
        <span class="text_19" @click="goPage('product')">Product</span>
        <div class="image-text_2 flex-row justify-between">
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="./assets/img/FigmaDDSSlicePNG567cb441c93f0eedfc1069802473b1a9.png"
          />
          <span class="text-group_3">TARGET 4 U LIMITED </span>
        </div>
        <span class="text_20"  @click="goPage('services')">Services</span>
        <span class="text_21"  @click="goPage('contact')">Contact</span>
      </div>
      <span class="text_22">Contacts&nbsp;us</span>
  
    </div>
    <div class="section_11 flex-col">
      <div class="box_4 flex-col"></div>
      <span class="text_23">©2024 All  rights TARGET 4 U LIMITED </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg1:
            'url(https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNG374939c653e81035a67a783393575bfb.png) 100% no-repeat',
          lanhutext0: '85%',
          lanhutext1: 'Consistency'
        },
        {
          lanhuBg1:
            'url(https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNGc10382d6f3bc1f85db386d02e5f7b90e.png) 100% no-repeat',
          lanhutext0: '95%',
          lanhutext1: 'Improvement'
        },
        {
          lanhuBg1:
            'url(https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNG71dd2eff83f9557b450a9cacfda3ef9d.png) 100% no-repeat',
          lanhutext0: '90%',
          lanhutext1: 'Branching'
        }
      ],
      constants: {}
    };
  },
  methods: {
    goPage(path){
      this.$router.push({name:path})
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />