<template>
  <div class="page flex-col">
    <div class="group_1 flex-row">
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="./assets/img/FigmaDDSSlicePNGfb106f120b2264356d53971859400930.png"
      />
      <span class="text_1">TARGET 4 U LIMITED </span>
      <div class="group_2 flex-col justify-between">
        <span class="text_2" @click="goPage('home')">Home</span>
      
      </div>
      <span class="text_3" @click="goPage('product')">Product</span>
      <span class="text_4" @click="goPage('services')">Services</span>
      <span class="text_5" @click="goPage('contact')">Contact</span>
    </div>
    <div class="group_3 flex-row justify-between">
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/FigmaDDSSlicePNG7e8b891fb90d50c07c9fbb782f55592d.png"
      />
      <div class="section_1 flex-col justify-between">
        <div class="text-wrapper_1 flex-col"><span class="text_6">Excellent Projects</span></div>
        <span class="text_7">
          TARGET 4 U LIMITED has successfully completed a number of high-quality projects, including commercial centers, residential areas and public facilities, and has won wide acclaim.
        </span>
      </div>
    </div>
    <div class="group_4 flex-row justify-between">
      <div class="image-text_1 flex-row justify-between">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNGefe6556eef03a6bbea13fe41e33c6221.png"
        />
        <div class="text-group_1 flex-col justify-between">
          <span class="text_8">1,542+</span>
          <span class="text_9">Satisfied&nbsp;Clients</span>
        </div>
      </div>
      <div class="image-text_2 flex-row justify-between">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNGe69e04be516db5ba6aae643c29dfb449.png"
        />
        <div class="text-group_2 flex-col justify-between">
          <span class="text_10">182</span>
          <span class="text_11">Expert&nbsp;Team</span>
        </div>
      </div>
      <div class="image-text_3 flex-row justify-between">
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNGd0083af6c81bdf7ea26c8b90932a3c13.png"
        />
        <div class="text-group_3 flex-col justify-between">
          <span class="text_12">285</span>
          <span class="text_13">Activate&nbsp;Products</span>
        </div>
      </div>
      <div class="image-text_4 flex-row justify-between">
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNGcad92facb90078e38a42a75d05e7f5a0.png"
        />
        <div class="text-group_4 flex-col justify-between">
          <span class="text_14">28+</span>
          <span class="text_15">Awards&nbsp;Winning</span>
        </div>
      </div>
    </div>
    <div class="text-group_5 flex-col justify-between">
      <span class="text_16">Design Overview</span>
      <span class="text_17">Follow our cooperation projects and construction sites, looking forward to cooperating with you</span>
    </div>
    <div class="image-wrapper_1 flex-row">
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        :src="item.lanhuimage0"
        v-for="(item, index) in loopData0"
        :key="index"
      />
    </div>
    <div class="list_1 flex-row">
      <div class="text-group_6 flex-col justify-between" v-for="(item, index) in loopData1" :key="index">
        <span class="text_18" v-html="item.lanhutext0"></span>
        <span class="text_19" v-html="item.lanhutext1"></span>
      </div>
    </div>
    <div class="group_5 flex-col">
      <span class="text_20">Customer Comments</span>
      <div class="block_1 flex-row justify-between">
        <img
          class="image_8"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNG5d0feb08d1f2d9c9b1dc60807cdb862c.png"
        />
        <div class="group_6 flex-row">
          <div class="text-group_7 flex-col">
            <span class="text_21">
              We are very satisfied with the villa built for us by TARGET 4 U LIMITED. Their professional team showed excellent skills and a high sense of responsibility at every stage of construction. From design to construction, every detail was handled very well, ensuring that our villa is not only beautiful and elegant, but also fully functional. It is especially worth mentioning that they completed the project strictly according to the agreed time and budget without any delays or overspending. We feel very reassured and satisfied with TARGET 4 U LIMITED's efficient management and high-quality services. We strongly recommend them to any client who needs construction services.
            </span>
            <span class="text_22">Excellent Entrepreneur</span>
            <p>Customer</p>
          </div>
        </div>
      </div>
    </div>
    <div class="group_7 flex-row">
      <span class="text_24"  @click="goPage('home')">Home</span>
      <span class="text_25"  @click="goPage('product')">Product</span>
      <div class="image-text_5 flex-row justify-between">
        <img
          class="image_9"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNG567cb441c93f0eedfc1069802473b1a9.png"
        />
        <span class="text-group_8">TARGET 4 U LIMITED </span>
      </div>
      <span class="text_26" @click="goPage('services')">Services</span>
      <span class="text_27" @click="goPage('contact')">Contact</span>
    </div>
    <div class="group_8 flex-row">
      <span class="text_28">Contacts&nbsp;us</span>
      <div class="group_9 flex-col">
        <div class="group_10 flex-col"></div>
        <span class="text_29">©2024 All  rights TARGET 4 U LIMITED </span>
      </div>
    </div>
    <div class="text-wrapper_2 flex-row justify-between">
      <span class="text_30">Privacy&nbsp;Policy</span>
      <span class="text_31">Contacts&nbsp;us</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuimage0: 'https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNGdbc6a696e641213532f44bf10cf63305.png'
        },
        {
          lanhuimage0: 'https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNG7efce78acdf1fb61b2c44eec2173b91c.png'
        },
        {
          lanhuimage0: 'https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNG1e2fa386e62e5873742210dd41d49f23.png'
        }
      ],
      loopData1: [
        {
          lanhutext0: 'Construction site',
          lanhutext1:
            'Standardized management to ensure project progress'
        },
        {
          lanhutext0: 'Aesthetic design of space',
          lanhutext1:
            'Cooperate with multiple art galleries and museums to combine the beauty of architecture and nature'
        },
        {
          lanhutext0: 'Model demonstration',
          lanhutext1:
            'The most complete plan and model design, ensuring that customers can see the prototype of the project before cooperation'
        }
      ],
      constants: {}
    };
  },
  methods: {
    goPage(path){
      this.$router.push({name:path})
    }}
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />